import { createStore } from 'vuex'

const store = createStore({
  state: {
    urlArr: [],
    type: 0,
    size: 0,
  },
  getters: {
    getUrlArr(state) {
      return state.urlArr
    },
    getType(state) {
      return state.type
    },
    getSize(state) {
      return state.size
    }
  },
  mutations: {
    setUrlArr(state, newData) {
      state.urlArr = newData
    },
    setType(state, newData) {
      state.type = newData
    },
    setSize(state, newData) {
      state.size = newData
    },
  },
  actions: {}
})

export default store
