import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index'),
        redirect: '/home',
        meta: { title: "首页" },
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/home/home'),
                meta: { requiresAuth: true, title: '筛查指导说明', isHideBreadAll: true }
            },
            {
                path: '/screening',
                name: 'screening',
                component: () => import('@/views/screening/screening'),
                meta: { requiresAuth: true, title: '筛查', isHideBreadAll: true }
            },
            {
                path: '/personal',
                name: 'personal',
                component: () => import('@/views/personal/personal'),
                meta: { requiresAuth: true, title: '个人中心', isHideBreadAll: true }
            },
            {
                path: '/report',
                name: 'report',
                component: () => import('@/views/home/report'),
                meta: { requiresAuth: true, title: '筛查报告', isHideBreadAll: true }
            },
            {
                path: '/trainInfo',
                name: 'trainInfo',
                component: () => import('@/views/train/trainInfo'),
                meta: { requiresAuth: true, title: '训练详情', isHideBreadAll: true }
            },
            {
                path: '/testMethod',
                name: 'testMethod',
                component: () => import('@/views/eye/testMethod'),
                meta: { requiresAuth: true, title: '测试方法', isHideBreadAll: true }
            },
            {
                path: '/eyeTest',
                name: 'eyeTest',
                component: () => import('@/views/eye/eyeTest'),
                meta: { requiresAuth: true, title: '视力检测', isHideBreadAll: true }
            },
        ]
    },
    {
        path: '/',
        name: 'animation',
        component: () => import('@/views/animation'),
        meta: { requiresAuth: true, title: '亿目佳', isHideBreadAll: true }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login'),
        meta: { requiresAuth: true, title: '登录', isHideBreadAll: true }
    },
    {
        path: '/spreadIndex',
        name: 'spreadIndex',
        component: () => import('@/views/spread/spreadIndex'),
        meta: { requiresAuth: true, title: '亿目佳', isHideBreadAll: true }
    },
    {
        path: '/spreadResult',
        name: 'spreadResult',
        component: () => import('@/views/spread/spreadResult'),
        meta: { requiresAuth: true, title: '亿目佳', isHideBreadAll: true }
    },
    {
        path: '/game',
        name: 'game',
        component: () => import('@/views/screening/game'),
        meta: { requiresAuth: true, title: '筛查', isHideBreadAll: true }
    },
    {
        path: '/train',
        name: 'train',
        component: () => import('@/views/train/train'),
        meta: { requiresAuth: true, title: '训练', isHideBreadAll: true }
    },
    {
        path: '/spreadGame',
        name: 'spreadGame',
        component: () => import('@/views/spread/game'),
        meta: { requiresAuth: true, title: '筛查', isHideBreadAll: true }
    },
    {
        path: '/spreadTrain',
        name: 'spreadTrain',
        component: () => import('@/views/spread/train'),
        meta: { requiresAuth: true, title: '训练', isHideBreadAll: true }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.name === 'login' || !to.meta.requiresAuth) {
        return next()
    }
    const isLogin = true
    if (to.meta.requiresAuth && !isLogin) {
        return next('/')
    }
    next()
})

export default router
