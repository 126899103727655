import { createApp } from 'vue';
import 'ant-design-vue/dist/antd.css';
import App from './App';
import router from './router'
import './common.less'
import store from './store/store'

import { Select, SelectOption, Row, Col, Checkbox, Button, Tabs, Steps, Menu, Calendar, ConfigProvider, message, Input, DatePicker, Table, Form, Modal, Badge } from 'ant-design-vue';


const app = createApp(App);

app
    .use(store)
    .use(router)
    .use(Select)
    .use(SelectOption)
    .use(Row)
    .use(Col)
    .use(Checkbox)
    .use(Badge)
    .use(Button)
    .use(DatePicker)
    .use(Tabs)
    .use(Steps)
    .use(Input)
    .use(Menu)
    .use(Calendar)
    .use(ConfigProvider)
    .use(Table)
    .use(Form)
    .use(Modal)
    .mount('#app');

app.config.globalProperties.$message = message;